<script>
export default {
  props: ["user", "policy"],
  components: {
    MenuFilterByCityHq: () => import("./MenuFilterByCityHq.vue"),
    PolicyAccessCrmReservations: () => import("./PolicyAccessCrmReservations.vue"),
  },
  data: function() {
    return {
      myPolicy: {},
      resetValues: 0,
    };
  },
  computed: {
    isPolicyActive: function() {
      const isEnable = this.user.polices && this.user.polices[this.policy.key] && this.user.polices[this.policy.key].status;
      this.$store.state.user.numTest;
      return isEnable ?? false;
    },
    policyValue: function() {
      const value = this.user.polices && this.user.polices[this.policy.key] && this.user.polices[this.policy.key].value;
      this.$store.state.user.numTest;
      return this.convertToType(value, this.policy.valueType);
    },
    changedPoliceValues() {
      const noRequireVerify = !this.policy.hasValue && this.policy.valueType === "Boleean";

      if (noRequireVerify) {
        return true;
      }

      const isEqValue = this.myPolicy?.value !== this.policy?.value;
      const isEqPayload = JSON.stringify(this.myPolicy?.payload).length !== JSON.stringify(this.policy?.payload).length;
      this.$store.state.user.numTest;

      return isEqValue || isEqPayload;
    },
  },
  methods: {
    changeValue({ politic, target }) {
      let hasValue = true;

      if (target) {
        this.myPolicy["value"] = this.convertToType(target.value, this.policy.valueType);
        hasValue = !!target?.value;
      } else {
        const { allOptionsSeleted } = politic;

        hasValue = Object.keys(allOptionsSeleted || politic).length;
        this.myPolicy["payload"] = allOptionsSeleted || politic;
      }

      if (!hasValue) {
        this.myPolicy["status"] = false;
        this.$emit("change", { policy: this.myPolicy });
        this.resetValues++;
      }
    },
    changeToggle({ target }) {
      const checked = target.checked;

      if (checked && !this.changedPoliceValues) {
        target.checked = false;
        return;
      }
      if (!checked) {
        this.resetValues++;
      }

      this.myPolicy["status"] = checked;
      this.$emit("change", { policy: this.myPolicy });
    },
  },
  mounted: function() {
    if (!this.policy?.value) {
      this.policy.value = "";
    }
    if (!this.policy?.payload) {
      this.policy.payload = {};
    }
    this.myPolicy = structuredClone(this.policy);
  },
};
</script>

<template>
  <div :class="`user-policy`">
    <div class="user-policy-left" :key="resetValues">
      <div :class="`user-policy-desc policy-${user._id}-${policy.key}`">
        <span>{{ policy.label }}</span>
        <input
          :type="policy.valueType"
          class="input"
          placeholder="Ingresar valor"
          v-if="policy.hasValue"
          @change="changeValue"
          role="hasValue"
          :value="policyValue"
          ref="input"
        />
        <MenuFilterByCityHq
          v-if="policy._id === '64934d7e381ee813d8e14476'"
          ref="MenuFilterByCityHq"
          :policy="policy"
          :user="user"
          @result-updated="changeValue"
        />
        <PolicyAccessCrmReservations
          v-if="policy.key === 'Access-reservations-CMS-fields'"
          ref="AccessAdministration"
          :policy="policy"
          :user="user"
          @result-updated="changeValue"
        />
      </div>
    </div>
    <div class="user-policy-right">
      <ToggleSwitch :class="`policy-${user._id}-${policy.key}`" @change="changeToggle" ref="toggle" :checked="isPolicyActive" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-policy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px $alto;
  padding: $mpadding;
  .input {
    border: none;
    border-bottom: solid 1px $alto;
    padding: $mpadding/2 0;
    font-weight: bold;
  }
}
</style>
